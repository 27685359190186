import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Gini.module.scss";

import SingleLineChart from "../../components/chart/SingleLineChart";


const GiniCoefficients = () => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  async function getData1() {
    try {
      const response = await axios.get("/api/chart/ginichannelcount");
      setData1(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }

  async function getData2() {
    try {
      const response = await axios.get("/api/chart/giniliquidity");
      setData2(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData1();
    getData2();
  }, []);

  

  return (
    <div className={styles.home}>
      <Sidebar />
      <div className={styles.homeContainer}>
        <Navbar />
        <div className={styles.dashboard}>
         
          <div className={styles.charts}>
            <SingleLineChart className={styles.chart} title="Lightning Kanalų Skaičiaus Gini Koeficiantas" aspect={1.6/1} data={data1} YName={"Gini"} YStartFrom={0.5} XName={"Date"} />
          </div>

          <div className={styles.charts}>
            <SingleLineChart className={styles.chart} title="Lightning Likvidumo Gini Koeficiantas" aspect={1.6/1} data={data2} YName={"Gini"} YStartFrom={0.8} XName={"Date"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiniCoefficients;
