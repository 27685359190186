import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Distribution.module.scss";


import PercentageAreaChart from "../../components/chart/PercentageAreaChart";




const Distribution = () => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  async function getData1() {
    try {
      const response = await axios.get("/api/chart/distributionliquidity");
      setData1(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }

  async function getData2() {
    try {
      const response = await axios.get("/api/chart/distributionchannel");
      setData2(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }


  useEffect(() => {
    getData1();
    getData2();
  }, []);


  if(data1.length === 0 || data2.length === 0){
    return (
      <>
        Kraunasi...
      </>
    )
  }
  

  return (
    <div className={styles.home}>
      <Sidebar />
      <div className={styles.homeContainer}>
        <Navbar />
        <div className={styles.dashboard}>
       
          <div className={styles.charts}>
            <PercentageAreaChart className={styles.chart} title="Pasiskirstymas Pagal BTC Kiekį Kanaluose (TOP50 ir Kiti+TOP50 ir Kiti+Kiti)" aspect={1.6/1} data={data1} />
          </div>

          <div className={styles.charts}>
            <PercentageAreaChart className={styles.chart} title="Pasiskirstymas Pagal Kanalų Skaičių (TOP50 ir Kiti+TOP50 ir Kiti+Kiti)" aspect={1.6/1} data={data2} />
          </div>


        </div>
      </div>
    </div>
  );
};

export default Distribution;
