
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import styles from './LorenzCurvesByNodes.module.scss';

import Slider from '@mui/material/Slider';
import axios from "axios";


import LorenzCurveChart from "../../components/chart/LorenzCurveChart";

// Tabs
import { Box, Tab, Tabs } from '@mui/material';




const LorenzCurvesByNodes = () => {

  // Main Tabs
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };




  const currentDate = new Date();
  const startDate = new Date('2018-02-01');


  const [slider_selectedDate, setSlider_selectedDate] = useState(startDate);

  const handleSlider_selectedDate = (event, months) => {
    const newDate = new Date(startDate);
    newDate.setMonth(startDate.getMonth() + months);
    setSlider_selectedDate(newDate);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-01`;
  };



  /////////////////     Retrieve Data      /////////////////
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        let lorenzCurveType = "";
        if(tabIndex === 0)
          lorenzCurveType = "lorenzliquiditycurvebynodes";
        else if(tabIndex === 1)
          lorenzCurveType = "lorenzchannelcountcurvebynodes";
        

        const response = await axios.get("/api/chart/" + lorenzCurveType + "/" + formatDate(slider_selectedDate));
        setData(response.data);
      } catch (error) {
        console.error("There was an error fetching the Lorenz curve data:", error);
      }

    };
  
    fetchData();
  }, [slider_selectedDate, tabIndex]);






  






  if(data.length > 0){
    return (
      <>
        Kraunasi...
      </>
    )
  }
  return (
    <div className={styles.home}>
      <Sidebar />
      <div className={styles.homeContainer}>
        <Navbar />
        <div className={styles.dashboard} >

          <div style={{background: 'white', padding: 20}}>

            <div style={{marginBottom: 20, fontWeight: 'bold'}}>Lorenz Kreivės</div>

            <div style={{ padding: 20, border: '1px solid grey', borderRadius: 15 }}>
              <Typography id="non-linear-slider" gutterBottom>
                Pasirinkta Data: {formatDate(slider_selectedDate)}
              </Typography>
              <Slider
                defaultValue={0}
                min={0}
                step={1}
                max={67} // 5 years = 60 months
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const date = new Date(startDate);
                  date.setMonth(startDate.getMonth() + value);
                  return formatDate(date);
                }}
                onChange={handleSlider_selectedDate}
                sx={{
                  color: 'rgb(123, 0, 63)',
                  "&:hover": {
                    color: 'rgb(230, 65, 100)',
                  },
                }}
              />
            </div>
          


            
            <Box style={{marginTop: 20}}>
              <Tabs
                style={{
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "20px",
                  borderColor: "rgb(123, 0, 63)"
                }}

                indicatorColor="primary"
                value={tabIndex}
                onChange={handleTabChange}

                TabIndicatorProps={{
                  style: {
                    backgroundColor: "rgb(123, 0, 63)",
                    height: "100%",
                    zIndex: -1,
                    borderRadius: "15px",
                    textColor: "black"
                  }
                }}

                variant="fullWidth"
                sx={{
                  '& .MuiTabs-indicator': { backgroundColor: "rgb(123, 0, 63) !important" },
                  '& .Mui-selected': { color: "white !important"},
                  '& .MuiTabs-scroller': { zIndex: 0 },
                }}
              >
                <Tab value={0} style={{color: "black", fontWeight: "bold"}} disableRipple label="Pagal Mazgų BTC Kiekį" />
                <Tab value={1} style={{color: "black", fontWeight: "bold"}} disableRipple label="Pagal Kanalų Skaičių" />
              </Tabs>
            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 2}}>



              <div style={{ display: tabIndex === 0 ? 'block': 'none', height: 'calc(100vh - 300px)'}}>
                <div className={styles.charts}>
                  <LorenzCurveChart className={styles.chart} title="Lorenz Kreivė Pagal Mazgų BTC Kiekį" aspect={2.5/1} data={data.chartdata} xAxisKey="x" />
                </div>
              </div>
              
              
              <div style={{ display: tabIndex === 1 ? 'block': 'none', height: 'calc(100vh - 300px)'}}>
                <div className={styles.charts}>
                  <LorenzCurveChart className={styles.chart} title="Lorenz Kreivė Pagal Kanalų Skaičių" aspect={2.5/1} data={data.chartdata} xAxisKey="x" />
                </div>
              </div>
              
            </Box>
          </div>




        </div>
      </div>
    </div>

    
  )
};


export default LorenzCurvesByNodes;