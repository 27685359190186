import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Home.module.scss";

import SingleLineChart from "../../components/chart/SingleLineChart";


const Home = () => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  async function getData1() {
    try {
      const response = await axios.get("/api/chart/channelcount");
      setData1(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }

  async function getData2() {
    try {
      const response = await axios.get("/api/chart/nodecount");
      setData2(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }

  async function getData3() {
    try {
      const response = await axios.get("/api/chart/totalliquidity");
      setData3(response.data);
      console.log(response.status);
      if (response.status === 401) {
          // window.location.href = '/login';
      }
    } catch (error) {}
  }


  useEffect(() => {
    getData1();
    getData2();
    getData3();
  }, []);

  

  return (
    <div className={styles.home}>
      <Sidebar />
      <div className={styles.homeContainer}>
        <Navbar />
        <div className={styles.dashboard}>
         
          <div className={styles.charts}>
            <SingleLineChart className={styles.chart} title="Lightning Kanalų Skaičius" aspect={3/1} data={data1} YName={"KanaluSk"} XName={"Date"} />
          </div>

          <div className={styles.charts}>
            <SingleLineChart className={styles.chart} title="Lightning Mazgų Skaičius (Bent 1 atviras kanalas)" aspect={3/1} data={data2} YName={"MazguSk"} XName={"Date"} />
          </div>

          <div className={styles.charts}>
            <SingleLineChart className={styles.chart} title="Užšaldytų BTC Kanaluose kiekis" aspect={3/1} data={data3} YName={"BTC"} XName={"Date"} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Home;
