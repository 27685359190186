import styles from "./sidebar.module.scss";
import { useState } from "react";


// Collapse/Expand Sidebar
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HubIcon from '@mui/icons-material/Hub';
import TimelineIcon from '@mui/icons-material/Timeline';
import GrainIcon from '@mui/icons-material/Grain';


import { Link } from "react-router-dom";


const Sidebar = () => {
  const [open, setopen] = useState(localStorage.getItem("sidebarOpen") !== "false");

  const toggleOpen = () => {
    var sidebarOpendNewValue = !open;
    setopen(sidebarOpendNewValue)
    localStorage.setItem('sidebarOpen', sidebarOpendNewValue);
  }


  return (
    <div className={styles.sidebar}>
      <div className={styles.top}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div style={{border: '3px solid white', borderRadius: '50%', paddingTop: 2, paddingLeft: 2, paddingRight: 1}}>
            <CurrencyBitcoinOutlinedIcon className={styles.icon} style={{color: 'white', fontSize: 40}}/>
          </div>
        </Link>
      </div>
      <hr />
      <div className={styles.center}>
        
        <ul>
          <button className={styles.sidebarCollapseBtn} onClick={toggleOpen}>
            {open? <KeyboardDoubleArrowLeftIcon style={{verticalAlign: 'middle'}}/>:<KeyboardDoubleArrowRightIcon style={{verticalAlign: 'middle'}}/>}
          </button>


          {/* ---- */}
          {open? <p className={styles.title}>PAGRINDINIS</p>:<p className={styles.title}>-----</p>}
          
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <HomeOutlinedIcon className={styles.icon} />
              {open? <span>Bendri Rodikliai</span>:<></>}
            </li>
          </Link>

          {/* <Link to="/search" style={{ textDecoration: "none" }}>
            <li>
              <SearchOutlinedIcon className={styles.icon} />
              {open? <span>Paieška</span>:<></>}
            </li>
          </Link> */}



          <Link to="/distribution" style={{ textDecoration: "none" }}>
            <li>
              <TimelineIcon className={styles.icon} />
              {open? <span>Pasiskirstymas (TOP 50)</span>:<></>}
            </li>
          </Link>

          <Link to="/distribution10pcnt" style={{ textDecoration: "none" }}>
            <li>
              <TimelineIcon className={styles.icon} />
              {open? <span>Pasiskirstymas (TOP 10%)</span>:<></>}
            </li>
          </Link>

          <Link to="/gini" style={{ textDecoration: "none" }}>
            <li>
              <GrainIcon className={styles.icon} />
              {open? <span>Gini Koeficientai</span>:<></>}
            </li>
          </Link>

          <Link to="/lorenzbynodes" style={{ textDecoration: "none" }}>
            <li>
              <TimelineIcon className={styles.icon} />
              {open? <span>Lorenz Kreivės Pagal Nodes</span>:<></>}
            </li>
          </Link>

          <Link to="/lorenzbyentities" style={{ textDecoration: "none" }}>
            <li>
              <TimelineIcon className={styles.icon} />
              {open? <span>Lorenz Kreivės Pagal Entities</span>:<></>}
            </li>
          </Link>


          <Link to="/connections" style={{ textDecoration: "none" }}>
            <li>
              <HubIcon className={styles.icon} />
              {open? <span>Jungtys</span>:<></>}
            </li>
          </Link>






      



          

          

          

        </ul>
      </div>
    
    </div>
  );
};

export default Sidebar;
